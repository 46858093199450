<template>
  <div class="h-auto min-h-[90vh]">
    <div class="mt-0 md:mt-10 max-w-6xl px-0 md:px-6 mx-auto">
      <p
        class="hidden md:block my-3 font-desain text-[#212121] font-semibold text-2xl"
      >
        Profil
      </p>
      <div
        class="border-b-4 border-[#F9FCFF] bg-[#FFFFFF] flex md:hidden flex-row justify-between items-center px-3 pt-5 pb-0"
      >
        <span
          v-if="tab == 'profile'"
          class="font-solusi text-xl font-semibold text-[#212121]"
        >
          Profile Saya
        </span>
        <div
          v-else
          class="font-solusi text-xl font-semibold text-[#212121] flex items-center"
        >
          <svg
            @click="openTab('profile')"
            class="mr-2 w-5 h-5"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.07991 12.3117C2.08391 12.3227 2.08391 12.3337 2.08891 12.3447C2.08891 12.3467 2.08891 12.3477 2.08991 12.3497C2.14991 12.4917 2.23791 12.6117 2.33991 12.7197C2.35091 12.7317 2.35491 12.7467 2.36591 12.7587L10.0139 20.3367C10.2629 20.5827 10.5889 20.7057 10.9149 20.7057C11.2409 20.7057 11.5659 20.5827 11.8149 20.3367C12.3129 19.8437 12.3129 19.0457 11.8149 18.5527L6.34091 13.1277L21.1059 13.1277C21.8099 13.1277 22.3789 12.5637 22.3789 11.8657C22.3789 11.1697 21.8099 10.6047 21.1059 10.6047L6.34191 10.6047L11.8159 5.15269C12.3139 4.66069 12.3139 3.86169 11.8159 3.36969C11.3189 2.87669 10.5119 2.87669 10.0139 3.36969L2.36591 10.9737C2.25591 11.0827 2.17691 11.2117 2.11491 11.3477C2.09491 11.3897 2.08391 11.4307 2.06991 11.4727C2.03591 11.5747 2.01491 11.6767 2.00791 11.7847C2.00491 11.8287 1.99891 11.8697 2.00091 11.9137C2.00591 12.0497 2.02991 12.1817 2.07991 12.3117Z"
              fill="#212121"
            />
          </svg>
          Ganti Kata Sandi
        </div>

        <div
          @click="openLogout"
          class="rounded p-1 hover:bg-[#EEEEEE] duration-300 cursor-pointer flex flex-col items-center justify-center"
        >
          <svg
            class="w-6 h-6"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.91221 5.952C9.12408 5.98013 9.1794 6.01575 9.27782 6.08513C9.71189 6.39074 9.65657 7.15106 9.13345 7.39574C9.1147 7.40418 9.09595 7.41262 9.07814 7.42199C7.20875 8.32199 5.7959 10.1201 5.37782 12.1565C4.75343 15.1949 6.47001 18.5623 9.44462 19.7784C11.8888 20.7778 14.9178 20.2021 16.834 18.239C18.7858 16.2403 19.3053 12.989 18.0321 10.469C17.3506 9.12088 16.1974 8.01549 14.8343 7.38093C14.8343 7.38093 14.4003 7.1128 14.4003 6.70031C14.3993 6.18657 14.9909 5.79283 15.5347 6.0497C17.974 7.2047 19.7666 9.6065 20.1688 12.2887C20.5691 14.9568 19.5782 17.79 17.5533 19.6313C15.1646 21.8016 11.4578 22.3839 8.50818 21.0057C5.22306 19.4701 3.1785 15.645 3.88434 11.9606C4.36902 9.4312 6.11373 7.17376 8.4285 6.06472C8.55506 6.00472 8.72757 5.94285 8.91225 5.95035L8.91221 5.952ZM12.0454 2.25264C12.1898 2.26577 12.2132 2.27983 12.2751 2.3042C12.5235 2.40264 12.7101 2.63982 12.7438 2.90795C12.7495 2.95013 12.7485 2.96045 12.7495 3.00263V10.513C12.7438 10.6873 12.7213 10.7389 12.6773 10.8336C12.4588 11.2976 11.7117 11.397 11.3901 10.9554C11.2982 10.8279 11.2513 10.6723 11.2466 10.5139V3.00357C11.2513 2.85826 11.2626 2.83388 11.2832 2.77107C11.3704 2.50201 11.6207 2.29388 11.9038 2.25826C11.9938 2.24701 12.0154 2.25264 12.0444 2.25358L12.0454 2.25264Z"
              fill="#DE1306"
            />
          </svg>
          <div class="font-solusi text-2xs text-[#DE1306]">
            Keluar
          </div>
        </div>
      </div>
      <div class="bg-[#FFFFFF] w-full shadow-md rounded-md p-5 flex flex-row">
        <div class="w-[25%] hidden md:block">
          <div
            @click="openTab('profile')"
            class="cursor-pointer flex w-full text-sm py-3 px-3 border-l-4 font-solusi duration-300"
            :class="
              tab == 'profile'
                ? 'bg-[#F7FBFF] border-[#009DFF] text-[#0A72ED]'
                : 'border-transparent text-[#212121]'
            "
          >
            Ubah Profil
          </div>
          <div
            @click="openTab('password')"
            class="cursor-pointer flex w-full text-sm py-3 px-3 border-l-4 font-solusi duration-300"
            :class="
              tab == 'password'
                ? 'bg-[#F7FBFF] border-[#009DFF] text-[#0A72ED]'
                : 'border-transparent text-[#212121]'
            "
          >
            Ganti Kata Sandi
          </div>
        </div>

        <transition name="fade" mode="out-in" appear>
          <div
            v-if="tab == 'profile'"
            class="w-full md:w-[75%] md:px-20 flex flex-col"
          >
            <div
              class="relative mb-6 flex flex-col md:flex-row items-center md:items-start md:space-x-4"
            >
              <img
                v-if="
                  getProfileData?.photo &&
                    getProfileData?.photo !=
                      'https://platform.sekolahdesain.id/assets/images/navbar/profile2.svg'
                "
                :src="getProfileData?.photo"
                class="w-[100px] h-[100px] rounded-full mb-4 md:mb-0 flex-shrink-0"
                alt=""
              />
              <div
                v-else
                class="w-24 h-24 rounded-full text-center font-solusi capitalize align-middle text-white inline-flex justify-center items-center md:mr-1 font-semibold text-4xl flex-shrink-0"
                :style="'background-color: ' + toHex(getProfileData?.name)"
              >
                {{ getInitialName(getProfileData?.name) }}
              </div>
              <div
                class="w-full flex flex-col items-center md:items-start justify-between h-full"
              >
                <div
                  class="relative flex flex-row items-center mt-2 space-x-4 mb-4 md:mb-0"
                >
                  <label
                    for="photo"
                    class="font-solusi text-[#FFFFFF] bg-[#00446F] px-4 py-2 rounded-full text-sm font-semibold outline-none focus:outline-none cursor-pointer"
                  >
                    Unggah Foto Profil
                  </label>
                  <input
                    id="photo"
                    type="file"
                    hidden
                    ref="photo"
                    @change="onFileUpload"
                    accept="image/x-png,image/jpeg,image/jpg"
                  />
                  <svg
                    v-if="getProfileData?.photo"
                    @click="openModalDeletePhoto()"
                    class="absolute md:relative top-1.5 md:top-0 -right-10 md:right-0 w-6 h-6 text-[#333333] hover:text-[#DE1306] cursor-pointer"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.3337 4C14.3337 4.34189 14.0763 4.62367 13.7447 4.66218L13.667 4.66667H13.1038L12.2829 13.0128C12.1906 13.9513 11.4014 14.6667 10.4584 14.6667H5.54228C4.59926 14.6667 3.81006 13.9513 3.71775 13.0128L2.89682 4.66667H2.33366C1.96547 4.66667 1.66699 4.36819 1.66699 4C1.66699 3.63181 1.96547 3.33333 2.33366 3.33333H5.66699C5.66699 2.04467 6.71166 1 8.00033 1C9.28899 1 10.3337 2.04467 10.3337 3.33333H13.667C14.0352 3.33333 14.3337 3.63181 14.3337 4ZM9.50033 6.16667C9.24719 6.16667 9.038 6.35477 9.00489 6.59882L9.00033 6.66667V11.3333L9.00489 11.4012C9.038 11.6452 9.24719 11.8333 9.50033 11.8333C9.75346 11.8333 9.96265 11.6452 9.99576 11.4012L10.0003 11.3333V6.66667L9.99576 6.59882C9.96265 6.35477 9.75346 6.16667 9.50033 6.16667ZM6.50033 6.16667C6.24719 6.16667 6.038 6.35477 6.00489 6.59882L6.00033 6.66667V11.3333L6.00489 11.4012C6.038 11.6452 6.24719 11.8333 6.50033 11.8333C6.75346 11.8333 6.96265 11.6452 6.99576 11.4012L7.00033 11.3333V6.66667L6.99576 6.59882C6.96265 6.35477 6.75346 6.16667 6.50033 6.16667ZM8.00033 2.33333C7.44804 2.33333 7.00033 2.78105 7.00033 3.33333H9.00033C9.00033 2.78105 8.55261 2.33333 8.00033 2.33333Z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
                <div>
                  <p
                    class="font-solusi text-[#9E9E9E] text-xs text-center md:text-left"
                  >
                    Masks. 2Mb
                  </p>
                  <p
                    class="font-solusi text-[#9E9E9E] text-xs text-center md:text-left"
                  >
                    Format file: .jpg, .jpeg, .png
                  </p>
                </div>
              </div>
            </div>
            <div class="relative mb-3">
              <p class="font-solusi text-sm text-[#212121] mb-2">Email</p>
              <input
                type="text"
                disabled
                name="email-p"
                id="email-p"
                v-model="formProfile.email"
                class="w-full rounded-md border border-[#EEEEEE] p-2 font-solusi text-[#9E9E9E] text-sm"
                placeholder="Email"
              />
            </div>
            <div class="relative mb-3">
              <p class="font-solusi text-sm text-[#212121] mb-2">
                Nama Lengkap
              </p>
              <input
                type="text"
                name="full-name"
                id="full-name"
                v-model="formProfile.name"
                class="w-full rounded-md border  p-2 font-solusi text-sm focus:outline-none focus:ring-2  focus:ring-opacity-50"
                placeholder="Nama Lengkap"
                @change="checkName()"
                :class="
                  errors.name
                    ? 'text-[#DE1306] ring-[#DE1306] border-[#DE1306]'
                    : 'border-[#EEEEEE] text-[#212121] focus:ring-[#0A72ED]'
                "
              />
              <p v-if="errors.name" class="text-xs text-[#DE1306] font-solusi">
                Nama tidak boleh kosong
              </p>
            </div>
            <div class="relative mb-3">
              <p class="font-solusi text-sm text-[#212121] mb-2">
                Tanggal Lahir
              </p>
              <input
                type="date"
                name="date-birth"
                id="date-birth"
                v-model="formProfile.birthDate"
                class="w-full rounded-md border border-[#EEEEEE] p-2 font-solusi text-[#212121] text-sm focus:outline-none focus:ring-2 focus:ring-[#0A72ED] focus:ring-opacity-50"
              />
            </div>

            <div class="relative mb-3">
              <p class="font-solusi text-sm text-[#212121] mb-2">Domisili</p>
              <v-select
                :options="cityData"
                :clearable="false"
                label="name"
                v-model="formProfile.domisili"
                placeholder="Pilih Kota"
                class="focus:outline-none focus:ring-2 focus:ring-[#0A72ED] focus:ring-opacity-50"
              />
            </div>

            <div class="relative mb-3">
              <p class="font-solusi text-sm text-[#212121] mb-2">Alamat</p>
              <textarea
                name="address"
                id="address"
                cols="30"
                rows="3"
                v-model="formProfile.address"
                class="w-full rounded-md border border-[#EEEEEE] p-2 font-solusi text-[#212121] text-sm focus:outline-none focus:ring-2 focus:ring-[#0A72ED] focus:ring-opacity-50"
                placeholder="Alamat"
              ></textarea>
            </div>

            <div class="relative mb-3 grid grid-cols-2 gap-4">
              <div class="col-span-2 relative">
                <p class="font-solusi text-sm text-[#212121] mb-2">Whatsapp</p>
                <div class="relative">
                  <div
                    class="absolute top-0 left-0 px-1.5 py-2 z-10 rounded-l-md bg-[#EEEEEE]"
                  >
                    <span class="font-solusi text-sm text-[#9E9E9E]">+62</span>
                  </div>
                  <input
                    type="text"
                    name="whatsapp"
                    placeholder="Whatsapp"
                    v-model="formProfile.whatsapp"
                    @input="checkWA()"
                    class="h-10 block w-full rounded-md appearance-none font-solusi text-sm pl-12 pr-2 py-2 focus:outline-none focus:ring-2 focus:ring-opacity-50 border z-20 relative bg-transparent"
                    :class="
                      errors.whatsapp
                        ? 'text-[#DE1306] ring-[#DE1306] border-[#DE1306]'
                        : 'text-[#212121] focus:ring-[#0A72ED] border-[#EEEEEE]'
                    "
                  />
                </div>
                <p
                  v-if="errors.whatsapp"
                  class="text-xs text-[#DE1306] font-solusi"
                >
                  {{ message.whatsapp }}
                </p>
              </div>
            </div>
            <div
              @click="openTab('password')"
              class="block md:hidden font-solusi text-[#0A72ED] hover:text-[#00446F] font-semibold text-sm underline my-4"
            >
              Ganti Kata Sandi
            </div>
            <div class="flex flex-row items-center justify-center my-5">
              <button
                @click="EditSubmit"
                class=" text-sm font-semibold px-10 py-2 rounded-full font-solusi duration-500 flex items-center"
                :class="
                  checkFormProfile && !getProfileEditDataLoading
                    ? 'text-[#FFFFFF] bg-[#00446F]'
                    : 'text-[#9E9E9E] bg-[#F5F6F8] cursor-not-allowed'
                "
                :disabled="!checkFormProfile || getProfileEditDataLoading"
              >
                <svg
                  v-if="getProfileEditDataLoading"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="spinner"
                  class="w-5 h-5 animate-spin mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
                  ></path>
                </svg>
                Simpan Profil
              </button>
            </div>
          </div>
          <div
            v-else
            class="w-full md:w-[75%] min-h-[calc(100vh-10rem)] md:min-h-[calc(100vh-12rem)] md:px-20 flex flex-col"
          >
            <form @submit.prevent="EditPassword">
              <div class="relative mb-3">
                <p class="font-solusi text-sm text-[#212121] mb-2">
                  Kata Sandi Lama
                </p>
                <div class="relative">
                  <input
                    :type="showCurrentPassword ? 'text' : 'password'"
                    name="currentPassword"
                    placeholder="Masukkan Kata Sandi Lama"
                    v-model="formChangePassword.password"
                    autocomplete="on"
                    class="h-10 block w-full rounded-md appearance-none font-solusi text-sm pr-8 pl-3 py-2 focus:outline-none focus:ring-2 focus:ring-opacity-50 border z-0 relative bg-transparent"
                    :class="
                      errors.currentPassword
                        ? 'text-[#DE1306] ring-[#DE1306] border-[#DE1306]'
                        : 'text-[#212121] focus:ring-[#0A72ED] border-[#EEEEEE]'
                    "
                  />

                  <div
                    @click="toggleCurrentPassword"
                    class="absolute top-0 right-0 p-2 z-10 rounded-r-md cursor-pointer"
                  >
                    <svg
                      v-if="showCurrentPassword"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.4412 14.5821C16.9013 13.5544 18.0356 12.1014 18.634 10.3638C18.6783 10.2358 18.6783 10.0966 18.634 9.96937C17.4245 6.45558 14.0222 4.09512 10.1668 4.09512C8.66165 4.09512 7.22835 4.45876 5.96514 5.10619L2.70351 1.84456C2.46635 1.6074 2.08217 1.6074 1.84501 1.84456C1.60785 2.08172 1.60785 2.4659 1.84501 2.70306L4.89253 5.75058C3.43246 6.77825 2.29812 8.23126 1.69971 9.96882C1.65544 10.0969 1.65544 10.236 1.69971 10.3633C3.44911 15.4471 9.52341 17.7088 14.3678 15.2257L17.6294 18.4873C17.8666 18.7245 18.2508 18.7245 18.4879 18.4873C18.7251 18.2502 18.7251 17.866 18.4879 17.6288L15.4412 14.5821ZM10.167 5.30939C13.4311 5.30939 16.3157 7.25248 17.4143 10.1663C16.8602 11.6359 15.8507 12.8581 14.5686 13.7086L13.133 12.2731C13.5575 11.6778 13.8097 10.9521 13.8097 10.1664C13.8097 8.15765 12.1757 6.52366 10.167 6.52366C9.38122 6.52366 8.65552 6.77584 8.05949 7.20035L6.8745 6.01536C7.88084 5.56161 8.9986 5.30943 10.1671 5.30943L10.167 5.30939ZM8.08236 8.94015L11.393 12.2508C11.0317 12.4642 10.6159 12.5946 10.1669 12.5946C8.82773 12.5946 7.73841 11.5053 7.73841 10.1662C7.73841 9.71713 7.86884 9.30134 8.08228 8.94007L8.08236 8.94015ZM8.94086 8.08164C9.30213 7.8682 9.71793 7.73777 10.167 7.73777C11.5061 7.73777 12.5954 8.8271 12.5954 10.1662C12.5954 10.6153 12.465 11.0311 12.2515 11.3923L8.94086 8.08164ZM2.91948 10.1663C3.47364 8.69671 4.48313 7.47453 5.76524 6.62396L7.20082 8.05954C6.77631 8.6548 6.52413 9.38049 6.52413 10.1662C6.52413 12.1749 8.15813 13.8089 10.1668 13.8089C10.9526 13.8089 11.6783 13.5568 12.2743 13.1322L13.4593 14.3172C9.36837 16.1591 4.46712 14.2714 2.91938 10.1662L2.91948 10.1663Z"
                        fill="#212121"
                      />
                    </svg>
                    <svg
                      v-else
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0001 16.6668C3.71892 16.6668 0.962166 10.6496 0.833366 10.4168C0.732584 10.2355 0.732714 9.7647 0.833496 9.58344C0.962402 9.35062 3.71892 3.33344 10.0001 3.33344C16.2813 3.33344 19.038 9.7673 19.1668 10.0001C19.2676 10.1814 19.2676 10.2355 19.1668 10.4168C19.0379 10.6496 16.2813 16.6668 10.0001 16.6668ZM2.08348 10.0001C2.79676 11.0813 5.22192 15.4168 10.0001 15.4168C14.7931 15.4168 17.2058 11.0828 17.9168 9.99996C17.2036 8.91872 14.7783 4.58344 10.0001 4.58344C5.20712 4.58344 2.79448 8.9173 2.08348 10.0001ZM10.0001 13.6C8.01496 13.6 6.40012 11.9851 6.40012 9.99996C6.40012 8.0148 8.01496 6.39996 10.0001 6.39996C11.9853 6.39996 13.6001 8.0148 13.6001 9.99996C13.6001 11.9851 11.9853 13.6 10.0001 13.6ZM10.0001 7.59996C8.67668 7.59996 7.60012 8.67652 7.60012 9.99996C7.60012 11.3234 8.67668 12.4 10.0001 12.4C11.3236 12.4 12.4001 11.3234 12.4001 9.99996C12.4001 8.67652 11.3236 7.59996 10.0001 7.59996Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                </div>
                <p
                  v-if="errors.currentPassword"
                  class="text-xs text-[#DE1306] font-solusi"
                >
                  {{ message.currentPassword }}
                </p>
              </div>

              <div class="relative mb-3">
                <p class="font-solusi text-sm text-[#212121] mb-2">
                  Kata Sandi Baru
                </p>
                <div class="relative">
                  <input
                    :type="showNewPassword ? 'text' : 'password'"
                    name="new-password"
                    placeholder="Masukkan Kata Sandi Baru"
                    v-model="formChangePassword.newPassword"
                    @input="checkNewPassword"
                    autocomplete="on"
                    class="h-10 block w-full rounded-md appearance-none font-solusi text-sm pr-8 pl-3 py-2 focus:outline-none focus:ring-2 focus:ring-opacity-50 border z-0 relative bg-transparent"
                    :class="
                      errors.newPassword
                        ? 'text-[#DE1306] ring-[#DE1306] border-[#DE1306]'
                        : 'text-[#212121] focus:ring-[#0A72ED] border-[#EEEEEE]'
                    "
                  />

                  <div
                    @click="toggleNewPassword"
                    class="absolute top-0 right-0 p-2 z-10 rounded-r-md cursor-pointer"
                  >
                    <svg
                      v-if="showNewPassword"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.4412 14.5821C16.9013 13.5544 18.0356 12.1014 18.634 10.3638C18.6783 10.2358 18.6783 10.0966 18.634 9.96937C17.4245 6.45558 14.0222 4.09512 10.1668 4.09512C8.66165 4.09512 7.22835 4.45876 5.96514 5.10619L2.70351 1.84456C2.46635 1.6074 2.08217 1.6074 1.84501 1.84456C1.60785 2.08172 1.60785 2.4659 1.84501 2.70306L4.89253 5.75058C3.43246 6.77825 2.29812 8.23126 1.69971 9.96882C1.65544 10.0969 1.65544 10.236 1.69971 10.3633C3.44911 15.4471 9.52341 17.7088 14.3678 15.2257L17.6294 18.4873C17.8666 18.7245 18.2508 18.7245 18.4879 18.4873C18.7251 18.2502 18.7251 17.866 18.4879 17.6288L15.4412 14.5821ZM10.167 5.30939C13.4311 5.30939 16.3157 7.25248 17.4143 10.1663C16.8602 11.6359 15.8507 12.8581 14.5686 13.7086L13.133 12.2731C13.5575 11.6778 13.8097 10.9521 13.8097 10.1664C13.8097 8.15765 12.1757 6.52366 10.167 6.52366C9.38122 6.52366 8.65552 6.77584 8.05949 7.20035L6.8745 6.01536C7.88084 5.56161 8.9986 5.30943 10.1671 5.30943L10.167 5.30939ZM8.08236 8.94015L11.393 12.2508C11.0317 12.4642 10.6159 12.5946 10.1669 12.5946C8.82773 12.5946 7.73841 11.5053 7.73841 10.1662C7.73841 9.71713 7.86884 9.30134 8.08228 8.94007L8.08236 8.94015ZM8.94086 8.08164C9.30213 7.8682 9.71793 7.73777 10.167 7.73777C11.5061 7.73777 12.5954 8.8271 12.5954 10.1662C12.5954 10.6153 12.465 11.0311 12.2515 11.3923L8.94086 8.08164ZM2.91948 10.1663C3.47364 8.69671 4.48313 7.47453 5.76524 6.62396L7.20082 8.05954C6.77631 8.6548 6.52413 9.38049 6.52413 10.1662C6.52413 12.1749 8.15813 13.8089 10.1668 13.8089C10.9526 13.8089 11.6783 13.5568 12.2743 13.1322L13.4593 14.3172C9.36837 16.1591 4.46712 14.2714 2.91938 10.1662L2.91948 10.1663Z"
                        fill="#212121"
                      />
                    </svg>
                    <svg
                      v-else
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0001 16.6668C3.71892 16.6668 0.962166 10.6496 0.833366 10.4168C0.732584 10.2355 0.732714 9.7647 0.833496 9.58344C0.962402 9.35062 3.71892 3.33344 10.0001 3.33344C16.2813 3.33344 19.038 9.7673 19.1668 10.0001C19.2676 10.1814 19.2676 10.2355 19.1668 10.4168C19.0379 10.6496 16.2813 16.6668 10.0001 16.6668ZM2.08348 10.0001C2.79676 11.0813 5.22192 15.4168 10.0001 15.4168C14.7931 15.4168 17.2058 11.0828 17.9168 9.99996C17.2036 8.91872 14.7783 4.58344 10.0001 4.58344C5.20712 4.58344 2.79448 8.9173 2.08348 10.0001ZM10.0001 13.6C8.01496 13.6 6.40012 11.9851 6.40012 9.99996C6.40012 8.0148 8.01496 6.39996 10.0001 6.39996C11.9853 6.39996 13.6001 8.0148 13.6001 9.99996C13.6001 11.9851 11.9853 13.6 10.0001 13.6ZM10.0001 7.59996C8.67668 7.59996 7.60012 8.67652 7.60012 9.99996C7.60012 11.3234 8.67668 12.4 10.0001 12.4C11.3236 12.4 12.4001 11.3234 12.4001 9.99996C12.4001 8.67652 11.3236 7.59996 10.0001 7.59996Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                </div>
                <p
                  v-if="errors.newPassword"
                  class="text-xs text-[#DE1306] font-solusi"
                >
                  {{ message.newPassword }}
                </p>
              </div>

              <div class="relative mb-3">
                <p class="font-solusi text-sm text-[#212121] mb-2">
                  Konfirmasi Kata Sandi Baru
                </p>
                <div class="relative">
                  <input
                    :type="showNewPasswordConfirm ? 'text' : 'password'"
                    name="new-password-confirm"
                    placeholder="Masukkan Konfirmasi Kata Sandi Baru"
                    v-model="formChangePassword.newPasswordConfirm"
                    @input="checkNewPassword"
                    autocomplete="on"
                    class="h-10 block w-full rounded-md appearance-none font-solusi text-sm pr-8 pl-3 py-2 focus:outline-none focus:ring-2 focus:ring-opacity-50 border z-0 relative bg-transparent"
                    :class="
                      errors.newPasswordConfirm
                        ? 'text-[#DE1306] ring-[#DE1306] border-[#DE1306]'
                        : 'text-[#212121] focus:ring-[#0A72ED] border-[#EEEEEE]'
                    "
                  />
                  <div
                    @click="toggleNewPasswordConfirm"
                    class="absolute top-0 right-0 p-2 z-10 rounded-r-md cursor-pointer"
                  >
                    <svg
                      v-if="showNewPasswordConfirm"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.4412 14.5821C16.9013 13.5544 18.0356 12.1014 18.634 10.3638C18.6783 10.2358 18.6783 10.0966 18.634 9.96937C17.4245 6.45558 14.0222 4.09512 10.1668 4.09512C8.66165 4.09512 7.22835 4.45876 5.96514 5.10619L2.70351 1.84456C2.46635 1.6074 2.08217 1.6074 1.84501 1.84456C1.60785 2.08172 1.60785 2.4659 1.84501 2.70306L4.89253 5.75058C3.43246 6.77825 2.29812 8.23126 1.69971 9.96882C1.65544 10.0969 1.65544 10.236 1.69971 10.3633C3.44911 15.4471 9.52341 17.7088 14.3678 15.2257L17.6294 18.4873C17.8666 18.7245 18.2508 18.7245 18.4879 18.4873C18.7251 18.2502 18.7251 17.866 18.4879 17.6288L15.4412 14.5821ZM10.167 5.30939C13.4311 5.30939 16.3157 7.25248 17.4143 10.1663C16.8602 11.6359 15.8507 12.8581 14.5686 13.7086L13.133 12.2731C13.5575 11.6778 13.8097 10.9521 13.8097 10.1664C13.8097 8.15765 12.1757 6.52366 10.167 6.52366C9.38122 6.52366 8.65552 6.77584 8.05949 7.20035L6.8745 6.01536C7.88084 5.56161 8.9986 5.30943 10.1671 5.30943L10.167 5.30939ZM8.08236 8.94015L11.393 12.2508C11.0317 12.4642 10.6159 12.5946 10.1669 12.5946C8.82773 12.5946 7.73841 11.5053 7.73841 10.1662C7.73841 9.71713 7.86884 9.30134 8.08228 8.94007L8.08236 8.94015ZM8.94086 8.08164C9.30213 7.8682 9.71793 7.73777 10.167 7.73777C11.5061 7.73777 12.5954 8.8271 12.5954 10.1662C12.5954 10.6153 12.465 11.0311 12.2515 11.3923L8.94086 8.08164ZM2.91948 10.1663C3.47364 8.69671 4.48313 7.47453 5.76524 6.62396L7.20082 8.05954C6.77631 8.6548 6.52413 9.38049 6.52413 10.1662C6.52413 12.1749 8.15813 13.8089 10.1668 13.8089C10.9526 13.8089 11.6783 13.5568 12.2743 13.1322L13.4593 14.3172C9.36837 16.1591 4.46712 14.2714 2.91938 10.1662L2.91948 10.1663Z"
                        fill="#212121"
                      />
                    </svg>
                    <svg
                      v-else
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0001 16.6668C3.71892 16.6668 0.962166 10.6496 0.833366 10.4168C0.732584 10.2355 0.732714 9.7647 0.833496 9.58344C0.962402 9.35062 3.71892 3.33344 10.0001 3.33344C16.2813 3.33344 19.038 9.7673 19.1668 10.0001C19.2676 10.1814 19.2676 10.2355 19.1668 10.4168C19.0379 10.6496 16.2813 16.6668 10.0001 16.6668ZM2.08348 10.0001C2.79676 11.0813 5.22192 15.4168 10.0001 15.4168C14.7931 15.4168 17.2058 11.0828 17.9168 9.99996C17.2036 8.91872 14.7783 4.58344 10.0001 4.58344C5.20712 4.58344 2.79448 8.9173 2.08348 10.0001ZM10.0001 13.6C8.01496 13.6 6.40012 11.9851 6.40012 9.99996C6.40012 8.0148 8.01496 6.39996 10.0001 6.39996C11.9853 6.39996 13.6001 8.0148 13.6001 9.99996C13.6001 11.9851 11.9853 13.6 10.0001 13.6ZM10.0001 7.59996C8.67668 7.59996 7.60012 8.67652 7.60012 9.99996C7.60012 11.3234 8.67668 12.4 10.0001 12.4C11.3236 12.4 12.4001 11.3234 12.4001 9.99996C12.4001 8.67652 11.3236 7.59996 10.0001 7.59996Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                </div>
                <p
                  v-if="errors.newPasswordConfirm"
                  class="text-xs text-[#DE1306] font-solusi"
                >
                  {{ message.newPasswordConfirm }}
                </p>
              </div>

              <div class="flex flex-row items-center justify-center my-5">
                <button
                  type="submit"
                  @click="submitChangePassword"
                  class=" text-sm font-semibold px-10 py-2 rounded-full font-solusi duration-500 flex items-center"
                  :class="
                    checkFormChangePassword && !loadingChangePassword
                      ? 'text-[#FFFFFF] bg-[#00446F]'
                      : 'text-[#9E9E9E] bg-[#F5F6F8] cursor-not-allowed'
                  "
                  :disabled="!checkFormChangePassword || loadingChangePassword"
                >
                  <svg
                    v-if="loadingChangePassword"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="spinner"
                    class="w-5 h-5 animate-spin mr-2"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
                    ></path>
                  </svg>
                  Ganti Kata Sandi
                </button>
              </div>
            </form>
          </div>
        </transition>
      </div>

      <transition name="fade">
        <div
          v-if="showModalPhoto"
          class="opacity-50 fixed inset-0 z-50 bg-black"
        ></div>
      </transition>
      <transition
        enter-active-class="animated bounceIn"
        leave-active-class="animated zoomOut"
      >
        <div
          v-if="showModalPhoto"
          class="overflow-x-hidden overflow-y-auto fixed inset-0 ouline-none focus:outline-none flex justify-center items-center z-51"
        >
          <div
            class="relative w-full md:w-1/3 my-6 mx-4 md:mx-auto p-6 bg-[#FFFFFF] rounded-md"
          >
            <cropper
              ref="cropper"
              class="cropper"
              :src="image"
              :stencil-props="{
                aspectRatio: 1 / 1,
              }"
              @change="change"
            />
            <div class="pt-6 flex justify-center space-x-5">
              <button
                @click="closeModalPhoto"
                :disabled="loadingPhoto"
                class="font-solusi font-semibold text-base px-6 py-1.5 text-[#00446F] bg-[#FFFFFF] border border-[#00446F] rounded-full"
                :class="loadingPhoto ? 'cursor-not-allowed' : ''"
              >
                Batal
              </button>
              <button
                @click="cropImage"
                class="font-solusi font-semibold text-base px-6 py-1.5 border rounded-full duration-500 flex items-center justify-center"
                :class="
                  loadingPhoto
                    ? 'text-[#9E9E9E] bg-[#EEEEEE] border-[#9E9E9E] cursor-wait'
                    : 'text-[#FFFFFF] bg-[#00446F] border-[#00446F]'
                "
                :disabled="loadingPhoto"
              >
                <svg
                  v-if="loadingPhoto"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="spinner"
                  class="w-5 h-5 animate-spin mr-2"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
                  ></path>
                </svg>
                Upload Foto
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <ModalLogout
      :show="showModalLogout"
      :close="closeLogout"
      :submit="logout"
    />

    <ModalDelete
      title="Hapus Foto Profil"
      item="foto profil ini"
      :showDelete="showModalDeletePhoto"
      :closeDelete="closeModalDeletePhoto"
      :submitDelete="deletePhoto"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Auth from '@/utils/Auth'
import moment from 'moment'
import { useToast } from 'vue-toastification'
import ModalLogout from '@/main/components/Modals/ModalLogout.vue'
import ModalDelete from '@/main/components/Modals/ModalDelete.vue'
import UserModel from '@/models/UserModel'

/* eslint-disable @typescript-eslint/camelcase */
export default defineComponent({
  name: 'Profile',
  components: {
    ModalLogout,
    ModalDelete,
  },
  setup() {
    const Toast = useToast()
    return {
      Auth,
      Toast,
    }
  },

  data() {
    return {
      showModalLogout: false,
      tab: 'profile',
      user: {},
      cityData: [],
      file: null,
      image: null,
      loadingPhoto: false,
      showModalPhoto: false,
      showModalDeletePhoto: false,
      formProfile: {
        email: '',
        name: '',
        address: '',
        birthDate: '',
        domisili: {
          id: 0,
          name: null,
        },
        whatsapp: '',
        gender: '',
        instagram: '',
      },
      errors: {
        name: false,
        whatsapp: false,
        currentPassword: false,
        newPassword: false,
        newPasswordConfirm: false,
      },
      message: {
        name: '',
        whatsapp: '',
        currentPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
      formChangePassword: {
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
      showCurrentPassword: false,
      showNewPassword: false,
      showNewPasswordConfirm: false,
      loadingChangePassword: false,
    }
  },

  async mounted() {
    await this.getProfile()
    this.reorderingCity()
  },

  computed: {
    getCityData() {
      return this.$store.getters['cityStore/getCityData']
    },
    getProfileData() {
      return this.$store.getters['profileStore/getProfileData']
    },
    getProfileEditDataLoading() {
      return this.$store.getters['profileStore/getProfileEditDataLoading']
    },
    checkFormProfile() {
      if (!this.errors.name && !this.errors.whatsapp) {
        return true
      } else {
        return false
      }
    },
    checkFormChangePassword() {
      if (
        this.formChangePassword.password != '' &&
        this.formChangePassword.newPassword != '' &&
        this.formChangePassword.newPasswordConfirm != '' &&
        this.errors.newPassword == false &&
        this.errors.newPasswordConfirm == false
      ) {
        return true
      } else {
        return false
      }
    },
  },

  methods: {
    cropImage() {
      const { canvas } = this.$refs.cropper.getResult()
      canvas.toBlob(blob => {
        blob.name = this.file.name
        blob.lastModifiedDate = new Date()
        const image = new File([blob], blob.name, { type: 'image/png' })
        this.EditPhoto(image)
      })
    },
    async onFileUpload(event) {
      const size = event.target.files[0].size
      if (size > 2097152) {
        this.Toast.error('Ukuran foto maksimal 2Mb')
        return
      }
      const fileName = event.target.files[0].name.substr(-3).toLowerCase()
      if (['png', 'jpg', 'peg'].indexOf(fileName) <= -1) {
        this.Toast.error('Format file tidak sesuai')
        return
      }
      this.file = event.target.files[0]
      this.image = URL.createObjectURL(event.target.files[0])
      if (this.image) {
        this.showModalPhoto = true
      }
    },
    async EditPhoto(image) {
      this.loadingPhoto = true
      try {
        this.$store.dispatch('profileStore/fetchUploadProfilePhoto', image)
        await UserModel.editPhoto(image)
        this.$store.dispatch('profileStore/fetchProfileData')
        this.showModalPhoto = false
        this.loadingPhoto = false
        this.Toast.success('Berhasil ubah profil')
      } catch (error) {
        this.loadingPhoto = false
        console.log(error)
      }
    },
    closeModalPhoto() {
      this.image = null
      this.file = null
      this.showModalPhoto = false
    },
    openModalDeletePhoto() {
      this.showModalDeletePhoto = true
    },
    closeModalDeletePhoto() {
      this.showModalDeletePhoto = false
    },
    async deletePhoto() {
      await UserModel.removePhoto()
        .then(res => {
          this.closeModalDeletePhoto()
          if (res.success) {
            this.Toast.success('Berhasil menghapus foto')
            this.$store.dispatch('profileStore/fetchProfileData')
          } else {
            this.Toast.error('Gagal menghapus foto')
          }
        })
        .catch(err => {
          this.Toast.error('Gagal menghapus foto')
        })
    },
    async logout() {
      await Auth.logout()
      window.location.assign('/')
    },
    openLogout() {
      this.showModalLogout = true
    },
    closeLogout() {
      this.showModalLogout = false
    },
    openTab(tab) {
      window.scrollTo(0, 0)
      this.tab = tab
    },
    reorderingCity() {
      this.cityData = this.getCityData.data.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      )
    },
    toggleCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword
    },
    toggleNewPassword() {
      this.showNewPassword = !this.showNewPassword
    },
    toggleNewPasswordConfirm() {
      this.showNewPasswordConfirm = !this.showNewPasswordConfirm
    },
    async getProfile() {
      // fetch city list data
      await this.$store.dispatch('cityStore/fetchCityList')

      // fetch profile data
      await this.$store.dispatch('profileStore/fetchProfileData').then(() => {
        const {
          email,
          name,
          address,
          instagram,
          whatsapp,
          city_id,
          birth_date,
        } = this.getProfileData
        this.formProfile.name = name
        this.formProfile.email = email
        this.formProfile.address = address ? address : null
        this.formProfile.instagram = instagram != '' ? instagram : null
        const begin = whatsapp.substring(0, 2)
        if (begin == '08') {
          this.formProfile.whatsapp = whatsapp.substring(1)
        } else if (begin == '62') {
          this.formProfile.whatsapp = whatsapp.substring(2)
        } else {
          this.formProfile.whatsapp = whatsapp
        }
        const dateBirth = moment(birth_date).format('YYYY-MM-DD')
        if (dateBirth != '0001-01-01' && birth_date != null) {
          this.formProfile.birthDate = moment(birth_date).format('YYYY-MM-DD')
        } else {
          this.formProfile.birthDate = ''
        }

        if (city_id != 0 && city_id != null) {
          const city = this.getCityData.data.filter(
            e => e.id == this.getProfileData.city_id
          )
          this.formProfile.domisili.id = city[0].id
          this.formProfile.domisili.name = city[0].name
        } else {
          this.formProfile.domisili.id = 0
          this.formProfile.domisili.name = 'Pilih Kota'
        }
      })
    },
    checkName() {
      this.errors.name = false
      if (!this.formProfile.name) {
        this.errors.name = true
      }
    },

    checkWA() {
      this.errors.whatsapp = false
      this.message.whatsapp = ''

      const numbers = /^[+]?[0-9 ]+$/
      if (!this.formProfile.whatsapp.match(numbers)) {
        this.errors.whatsapp = true
        this.message.whatsapp = 'Nomor yang Anda masukkan harus angka.'
        return
      }

      if (this.formProfile.whatsapp.length < 9) {
        this.errors.whatsapp = true
        this.message.whatsapp =
          'Nomor yang Anda masukkan harus lebih dari 9 digit.'
        return
      }

      const no = this.formProfile.whatsapp.substring(0, 1)
      if (no !== '8') {
        this.errors.whatsapp = true
        this.message.whatsapp =
          'Nomor yang Anda masukkan harus diawali 8 (ex: 856 XXXX XXXX).'
        return
      }
    },
    async EditSubmit() {
      this.checkWA()
      this.checkName()
      if (!this.errors.whatsapp && !this.errors.name) {
        const whatsapp = '62' + this.formProfile.whatsapp
        try {
          await this.$store
            .dispatch('profileStore/fetchEditProfileData', {
              firstName: this.formProfile.name,
              whatsapp: whatsapp,
              instagram: '',
              address: this.formProfile.address,
              date: this.formProfile.birthDate,
              cityId: this.formProfile.domisili.id,
              city: this.formProfile.domisili.name,
              birthPlace: '',
            })
            .then(async () => {
              await this.$store.dispatch('profileStore/fetchProfileData')
              if (this.getProfileEditDataLoading === false) {
                this.Toast.success('Profil berhasil diubah')
              }
            })
        } catch (error) {
          const err = error
          this.Toast.error(err.response.data.error[0].message)
        }
      }
    },
    getInitialName(name) {
      if (name) {
        let initials = name.split(' ')

        if (initials.length > 1) {
          initials = initials.shift().charAt(0) + initials.pop().charAt(0)
        } else {
          initials = name.substring(0, 1)
        }
        return initials.toUpperCase()
      } else {
        return '@'
      }
    },
    toHex(name) {
      if (name) {
        let hash = 0
        if (name?.length === 0) return hash
        for (let i = 0; i < name.length; i++) {
          hash = name.charCodeAt(i) + ((hash << 5) - hash)
          hash = hash & hash
        }
        let color = '#'
        for (let i = 0; i < 3; i++) {
          const value = (hash >> (i * 8)) & 255
          color += ('00' + value.toString(16)).substr(-2)
        }
        return color
      } else {
        return '#EEEEEE'
      }
    },
    forgotPassword() {
      const route = process.env.VUE_APP_IDP_FORGOT_PASSWORD
      window.open(route, '_blank', 'width=900, height=600')
    },
    checkNewPassword() {
      this.errors.newPassword = false
      this.errors.newPasswordConfirm = false
      if (this.formChangePassword.newPassword.length < 8) {
        this.errors.newPassword = true
        this.message.newPassword =
          'Min. 8 karakter terdiri dari angka, huruf, simbol.'
      }
      if (this.formChangePassword.newPasswordConfirm != '') {
        if (
          this.formChangePassword.newPassword !==
          this.formChangePassword.newPasswordConfirm
        ) {
          this.errors.newPasswordConfirm = true
          this.message.newPasswordConfirm =
            'Konfirmasi kata sandi baru tidak sama dengan kata sandi baru.'
        }
      }
      return
    },
    async submitChangePassword() {
      this.errors.currentPassword = false
      this.loadingChangePassword = true
      await UserModel.changePassword(this.formChangePassword)
        .then(res => {
          this.loadingChangePassword = false
          if (res.success) {
            this.clearChangePassword()
            this.Toast.success('Kata Sandi berhasil diganti!')
            this.openTab('profile')
          } else {
            this.Toast.error('Terjadi kesalahan, Silahkan coba kembali!')
          }
        })
        .catch(err => {
          if (err.response.data.errors[0].message == 'Password lama salah') {
            this.errors.currentPassword = true
            this.message.currentPassword = 'Kata sandi lama anda salah.'
          }
          this.loadingChangePassword = false
          this.Toast.error(err.response.data.errors[0].message)
        })
    },
    clearChangePassword() {
      this.formChangePassword.password = ''
      this.formChangePassword.newPassword = ''
      this.formChangePassword.newPasswordConfirm = ''
    },
  },
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

::v-deep(.vs__selected) {
  font-family: 'SolusiSans', ui-sans-serif, system-ui, sans-serif;
}
::v-deep(.vs__dropdown-option) {
  font-family: 'SolusiSans', ui-sans-serif, system-ui, sans-serif;
}
::v-deep(.vs__dropdown-toggle) {
  height: 2.5rem;
  border: 1px solid #eeeeee;
}
::v-deep(.vs__selected, .vs__search, .vs__search:focus) {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}
.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
</style>
